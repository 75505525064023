var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mr-2"},[_c('v-card-title',{staticClass:"subheading"},[_vm._v(" Organisations "),_c('v-spacer'),_c('v-text-field',{attrs:{"cols":"1","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-btn',{on:{"click":function($event){return _vm.addOrganisation()}}},[_vm._v("Add organisation")])],1)],1),_c('v-card-text',[_c('v-switch',{attrs:{"label":"Active"},model:{value:(_vm.isTrue),callback:function ($$v) {_vm.isTrue=$$v},expression:"isTrue"}}),(_vm.isTrue)?_c('v-data-table',{attrs:{"id":"list-table","footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50] },"items-per-page":30,"loading":_vm.loading,"search":_vm.search,"headers":[
              { text: 'SKU', value: 'sku', align: ' d-none' },
              { text: 'ID', value: 'id' },
              { text: 'Name', value: 'name' },
              { text: 'Enabled', value: 'enabled' },
              { text: 'Users', value: 'users' },
              { text: 'Edit', value: 'edit' },
              
            ],"items":_vm.organisations,"item-key":"id"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(props.item.isEnabled),expression:"props.item.isEnabled"}]},[_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.id))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.name))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.isEnabled))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.users))]),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(props.item)}}},[_vm._v("mdi-pencil")])],1)])]}}],null,false,3201328626)}):_vm._e(),(!_vm.isTrue)?_c('v-data-table',{attrs:{"id":"list-table","footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50] },"items-per-page":30,"loading":_vm.loading,"search":_vm.search,"headers":[
              { text: 'SKU', value: 'sku', align: ' d-none' },
              { text: 'ID', value: 'id' },
              { text: 'Name', value: 'name' },
              { text: 'Enabled', value: 'enabled' },
              { text: 'Users', value: 'users' },
              { text: 'Edit', value: 'edit' },
              
            ],"items":_vm.inActiveOrganisations,"item-key":"id"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!props.item.isEnabled),expression:"!props.item.isEnabled"}]},[_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.id))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.name))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.isEnabled))]),_c('td',{attrs:{"text-xs-left":""}},[_vm._v(_vm._s(props.item.users))]),_c('td',[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(props.item)}}},[_vm._v("mdi-pencil")])],1)])]}}],null,false,3888739954)}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
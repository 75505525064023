<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mr-2">
          <v-card-title class="subheading">
            Organisations

            <v-spacer></v-spacer>

            <v-text-field
              cols="1"
              v-model="search"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
            <div>
              <v-btn @click="addOrganisation()">Add organisation</v-btn>
            </div>
            
          </v-card-title>
          <v-card-text>
          <v-switch label="Active" v-model="isTrue"/>
            <v-data-table
              v-if="isTrue"
              id="list-table"
              :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
              :items-per-page="30"
              :loading="loading"
              :search="search"
              v-bind:headers="[
                { text: 'SKU', value: 'sku', align: ' d-none' },
                { text: 'ID', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Enabled', value: 'enabled' },
                { text: 'Users', value: 'users' },
                { text: 'Edit', value: 'edit' },
                
              ]"
              :items="organisations"
              item-key="id"
            >
              <template slot="item" slot-scope="props">

               <!-- <template v-if="isTrue">  -->
                <tr v-show="props.item.isEnabled">
                  <td text-xs-left>{{ props.item.id }}</td>
                  <td text-xs-left>{{ props.item.name }}</td>
                  <td text-xs-left>{{ props.item.isEnabled }}</td>
                  <td text-xs-left>{{ props.item.users }}</td>

                  <td>
                    <v-icon small @click="editItem(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>
              </template>
                <!-- <template v-else>
                <tr v-show="!props.item.isEnabled">
                  <td text-xs-left>{{ props.item.id }}</td>
                  <td text-xs-left>{{ props.item.name }}</td>
                  <td text-xs-left>{{ props.item.isEnabled }}</td>
                  <td text-xs-left>{{ props.item.users }}</td>

                  <td>
                    <v-icon small @click="editItem(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>
                </template> -->
              <!-- </template> -->
            </v-data-table>

            <v-data-table
             v-if="!isTrue"
              id="list-table"
              :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
              :items-per-page="30"
              :loading="loading"
              :search="search"
              v-bind:headers="[
                { text: 'SKU', value: 'sku', align: ' d-none' },
                { text: 'ID', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Enabled', value: 'enabled' },
                { text: 'Users', value: 'users' },
                { text: 'Edit', value: 'edit' },
                
              ]"
              :items="inActiveOrganisations"
              item-key="id"
            >
              <template slot="item" slot-scope="props">

              <tr v-show="!props.item.isEnabled">
                  <td text-xs-left>{{ props.item.id }}</td>
                  <td text-xs-left>{{ props.item.name }}</td>
                  <td text-xs-left>{{ props.item.isEnabled }}</td>
                  <td text-xs-left>{{ props.item.users }}</td>

                  <td>
                    <v-icon small @click="editItem(props.item)">mdi-pencil</v-icon>
                  </td>
                </tr>

             </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

//import * as organisationService from "../../services/organisationService";

export default {
  data: () => ({
    organisations: [],
    inActiveOrganisations: [],
    search: "",
    orgList: [],
   isTrue: true,
   loading: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    
  },
  methods: {
    async init() {
      var me = this;
      //let numUsers = [];
      const orgRef = this.$db.collection("organisations").where("isEnabled","==",true);
      const snapshot = await orgRef.get();

      snapshot.forEach((doc) => {
        let org = doc.data();
        org.id = doc.id;
        me.organisations.push(org);
      });
       const orgRef1 = this.$db.collection("organisations").where("isEnabled","==",false);
      const snapshot1 = await orgRef1.get();

      snapshot1.forEach((doc) => {
        let org = doc.data();
        org.id = doc.id;
        me.inActiveOrganisations.push(org);
      });
      
      // console.log(me.organisations);
      
      // for (let i = 0; i < me.organisations.length; i++) {
      //   this.loading=true;
      //   const userRef = this.$db.collection("users");
      //   const snapshot2 = await userRef
      //     .where("organisationId", "==", me.organisations[i].id)
      //     .get();

      //   numUsers.push(snapshot2.size);
      //   // organisationService.addUsers(me.organisations[i].id, snapshot2.size).then((result) => {
      //   //         console.log(result);
      //   // }).catch((err) => {
      //   //     console.log(err);
      //   // });
    //}
        
       //me.orgList = me.organisations.map((item, i) => ({ ...item, users: numUsers[i] }));
      //this.loading=false;
      //console.log(me.orgList);
    },
    addOrganisation() {
      this.$store.commit("setCurrentOrganisation", null);
      this.$router.push("/system-admin/organisations/details");
    },
    editItem(data) {
      this.$store.commit("setCurrentOrganisation", JSON.parse(JSON.stringify(data)));
      this.$router.push("/system-admin/organisations/details");
    },
  },
};
</script>
